img {
  height: 100px;
  width: 150px;
}
.spacer{
  width: 100%;
  height: 1px;
  background-color: #D5DBDB;
}

h1 {
  line-height: 3rem;
}

.loading-container{
  text-align: center;
  align-items: center;
}